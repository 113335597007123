<template>
    <div class="container-user-project">
        <form class="user-project" @submit.prevent="submitForm" ref="formUserProject">
            <div class="user-project__title">
                Я — реалізую партійні проєкти
            </div>
            <div class="user-project__inputs">
                <div class="user-project__left">
                    <div class="user-project__project-field user-project__project-name">
                        <span class="input-label">Назва проєкту</span>
                        <span v-if="errors.title" class="error-label">{{ errors.title }}</span>
                        <input
                            name="title"
                            class="input-project-name"
                            type="text" v-model="title"
                        />
                    </div>
                    <div class="user-project__project-field user-project__user-status">
                        <span class="select-label">На яку проблему спрямований проект?</span>
                        <span v-if="errors.statusValue" class="error-label">{{ errors.statusValue }}</span>
                        <CustomSelectV2
                            :options="dropdownList"
                            v-model="statusValue"
                        />
                    </div>
                    <div class="user-project__project-field user-project__place" v-if="statusValue==='8'">
                        <span class="input-label">Вкажіть проблему проекту.</span>
                        <span v-if="errors.location" class="error-label">{{ errors.location }}</span>
                        <input
                            name="location"
                            class="input-project-name"
                            type="text"
                            v-model="location"
                        />
                    </div>
                    <div class="user-project__project-field user-project__file">
                        <span v-if="errors.additional_files" class="error-label">{{ errors.additional_files }}</span>
                        <span class="input-label">Головна ідея</span>
                        <input hidden type="file" id="main-idea-file" ref="addMainIdea" @change="mainIdeaUpload()"/>
                        <label class="label-for-file" for="main-idea-file"><span
                            v-if="additional_files">{{ additional_files.name }}</span></label>
                    </div>
                    <div class="user-project__project-field user-project__user-init">
                        <span v-if="errors.userIdsValue" class="error-label">{{ errors.userIdsValue }}</span>
                        <span class="select-label">Ініціатори</span>
                        <div :class="{'activeContent' : userIdsValue.length}">
                            <multiselect
                                v-model="userIdsValue"
                                id="ajax"
                                label="name"
                                track-by="code"
                                placeholder="Почніть вводити прізвище для пошуку"
                                open-direction="bottom"
                                :options="usersList"
                                :multiple="true"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :options-limit="20"
                                :limit="10"
                                :limit-text="limitText"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="true"
                                @search-change="asyncFind">
                                <template slot="tag" slot-scope="{ option, remove }">
                                    <p class="custom__tag">
                                        <span>{{ option.name }}</span>
                                        <span class="custom__remove" @click="remove(option)"> <img src="/resources/src/assets/images/cancel_black_24dp.svg" alt=""></span>
                                    </p>
                                </template>
                                <template slot="clear" slot-scope="props">
                                    <div class="multiselect__clear" v-if="userIdsValue.length"
                                         @mousedown.prevent.stop="clearAll(props.search)"></div>
                                </template>
                                <span slot="noResult">На жаль, нікого не знайдено</span>
                                <span slot="noOptions">
                                    На жаль нічого не знайдено
                                 </span>
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="user-project__right">
                    <div class="user-project__project-field user-project__user-status">
                        <span class="select-label">Цільова аудиторія</span>
                        <span v-if="errors.jobsTypeValue" class="error-label">{{ errors.jobsTypeValue }}</span>
                        <CustomSelectV2
                            :options="jobsTypeList"
                            v-model="jobsTypeValue"
                        />
                    </div>
                    <div class="user-project__inputs">
                        <div class="user-project__left">
                            <div class="user-project__project-field user-project__user-region">
                                <span class="select-label">Область</span>
                                <span v-if="errors.regionValue" class="error-label">{{ errors.regionValue }}</span>
                                <CustomSelectV2
                                    :options="regionList"
                                    v-model="regionValue"
                                    @input="getCitiesList"
                                />
                            </div>
                        </div>
                        <div class="user-project__right">
                            <div class="user-project__project-field user-project__user-region">
                                <span class="select-label">Місто</span>
                                <span v-if="errors.cityValue" class="error-label">{{ errors.cityValue }}</span>
                                <CustomSelectV2
                                    :options="citiesList"
                                    v-model="cityValue"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="user-project__project-field user-project__file">
                        <span v-if="errors.file" class="error-label">{{ errors.file }}</span>
                        <span class="input-label">Бюджет</span>
                        <input hidden type="file" id="add-file" ref="addFile" @change="fileUpload()"/>
                        <label class="label-for-file" for="add-file"><span v-if="file">{{ file.name }}</span></label>
                    </div>
                    <div class="user-project__project-field user-project__description">
                        <span v-if="errors.description" class="error-label">{{ errors.description }}</span>
                        <span class="input-label">Що інноваційного?</span>
                        <textarea
                            name="description"
                            class="input-project-name"
                            type="text"
                            v-model="description">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="user-project__btn">
                <button class="btn-cancel" @click="clearStatusUser" type="button">
                    Скасувати
                </button>
                <button class="btn-save" type="submit">
                    Зберегти
                </button>
            </div>
            <div class="form-popup" v-if="popupStatus" @click="closePopup"></div>
            <div class="popup-container" v-if="popupStatus">
                <div class="close-popup" @click="closePopup">&#10006;</div>
                <div class="popup-title">
                    Дякуємо!
                </div>
                <div class="popup-description">
                    Форма успішно збережена. <br> Будь ласка, закрийте це вікно, щоб перейти до списку.
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import CustomSelectV2 from "@/components/ui/CustomSelectV2";
import PromotionService from "@/services/PromotionService";
import Multiselect from 'vue-multiselect'
// import { ajaxFindCountry } from './countriesApi'


export default {
    name: "user-projects",
    components: {
        CustomSelectV2, Multiselect
    },
    data() {
        return {
            userStatus: [],
            jobsType: [],
            userIds: [],
            dropdownList: [],
            regionList: [],
            citiesList: [],
            jobsTypeList: [],
            usersList: [],
            statusValue: '',
            regionValue: '',
            cityValue: '',
            jobsTypeValue: '',
            userIdsValue: '',
            toggleButton: true,
            file: '',
            additional_files: '',
            title: '',
            location: '',
            description: '',
            errors: {
                user_status: '',
                job_type_id: '',
                user_ids: '',
                title: '',
                location: '',
                description: '',
                file: '',
                additional_files: '',
            },
            update: false,
            popupStatus: false,
            isLoading: false
        }
    },
    methods: {
        limitText(count) {
            return `ще ${count} партійців`
        },
        asyncFind(query) {
            this.isLoading = true
            this.getUsersList(query).then(response => {
                this.isLoading = false
            })
        },
        clearAll() {
            this.selectedCountries = []
        },
        clearStatusUser() {
            if (this.statusValue) {
                this.toggleButton = !this.toggleButton
                this.clearData()
            }
            this.$router.push('/encouragement/4')
        },
        fileUpload() {
            this.file = this.$refs.addFile.files[0];
        },
        mainIdeaUpload() {
            this.additional_files = this.$refs.addMainIdea.files[0];
        },
        async submitForm() {
            let formData = new FormData(this.$refs.formUserProject);

            if (this.file) {
                formData.append('files[0]', this.file)
            }

            if (this.additional_files) {
                formData.append('additionalFiles[0]', this.additional_files)
            }

            formData.append('user_status', this.statusValue)

            formData.append('region_id', this.regionValue)

            formData.append('area_id', this.cityValue)

            formData.append('job_type_id', this.jobsTypeValue)
            // for(let i=0; i<this.userIds.length; i++){
            //     formData.append('user_ids[i]', this.userIds[i]);
            // }
            for (let user_id in this.userIdsValue) {
                formData.append('user_ids[' + user_id + ']', this.userIdsValue[user_id].code);
            }


            if (!this.update) {
                let resp = await PromotionService.uploadProjectForm(formData, 4).catch(this.handleError)

                if (resp && resp.status === "success") {
                    this.clearData()
                    this.errors = {}
                    this.popupStatus = true
                }
            }

            if (this.update) {
                let resp = await PromotionService.uploadEditProject(formData, +this.$route.params.id)
                    .catch(this.handleError)

                if (resp) {
                    this.clearData()
                    this.errors = {}
                    this.popupStatus = true
                }
            }

        },
        clearData() {
            this.title = '';
            this.location = '';
            this.description = '';
            this.statusValue = '';
            this.regionValue = '';
            this.cityValue = '';
            this.jobsTypeValue = '';
            this.userIdsValue = '';
            this.file = null;
            this.additional_files = null;
        },
        handleError(resp) {
            if (resp.statusCode === 422) {
                this.errors = resp.data;
            }
        },
        async getStatusList() {
            this.userStatus = await PromotionService.getPromotionDropdowns()
            for (let status in this.userStatus) {
                this.dropdownList.push(
                    {"value": status, 'label': this.userStatus[status]}
                )
            }
        },
        async getRegionList() {
            this.regionValue = await PromotionService.getRegionList()
            for (let status in this.regionValue) {
                this.regionList.push(
                    {"value": this.regionValue[status].id, 'label': this.regionValue[status].text}
                )
            }
        },
        async getCitiesList(parentId) {
            this.cityValue = await PromotionService.getCitiesListByRegiom(parentId)
            this.citiesList = [];
            for (let status in this.cityValue) {
                this.citiesList.push(
                    {"value": this.cityValue[status].id, 'label': this.cityValue[status].text}
                )
            }
        },
        async getJobsTypeList() {
            this.jobsType = await PromotionService.getJobTypeDropdowns()
            for (let job_type in this.jobsType) {
                this.jobsTypeList.push(
                    {"value": job_type, 'label': this.jobsType[job_type]}
                )
            }
        }, async getUsersList(search) {
            this.userIds = await PromotionService.getUsersDropdown(search)
            this.usersList = [];
            for (let user_id in this.userIds) {
                this.usersList.push(
                    {"code": this.userIds[user_id].code, 'name': this.userIds[user_id].name}
                )
            }
        },
        async getProject() {
            if (this.update) {
                let resp = await PromotionService.getUserProject(+this.$route.params.id).catch(this.handleError)
                this.title = resp.title
                this.statusValue = String(resp.user_status)
                this.jobsTypeValue = String(resp.job_type_id)
                // this.userIdsValue = resp.user_ids
                this.location = resp.location
                this.description = resp.description
                this.file = resp.files[0]
                this.additional_files = resp.additionalFiles[0]
                if (resp.user_ids) {
                    this.userIdsValue = await PromotionService.getUsersByIds(resp.user_ids)
                }
                this.regionValue = String(resp.region_id)
                if (this.regionValue) {
                    await this.getCitiesList(this.regionValue);
                    this.cityValue = String(resp.area_id)
                }

            }
        },
        closePopup() {
            this.popupStatus = false
            this.$router.push('/encouragement/4')
        }
    },
    created() {
        this.getStatusList()
        this.getJobsTypeList()
        this.getRegionList()
        if (this.$route.name === 'user-project-update') {
            this.update = true
        }
        this.getProject()
        this.getUsersList()
    },
    watch: {
        statusValue: function () {
            this.statusValue ? this.toggleButton = false : this.toggleButton = true
        },
    }
}
</script>

<style scoped lang="less">
.container-user-project {
    background-color: #f5f5f5;
    width: 100%;
    min-height: 100vh;
    float: right;
    display: flex;
    justify-content: center;
    align-items: baseline;

    @media (max-width: 992px) {
        width: 100%;
    }

    .user-project {
        margin: 36px 20px;
        padding: 0 40px;
        width: 1200px;
        min-height: 525px;
        border-radius: 10px;
        border: solid 1px #cdd4e0;
        background-color: #fff;
        @media (max-width: 992px) {
            margin: 36px 0;
        }

        &__title {
            font-family: @secondFontFamily;
            margin-top: 50px;
            font-weight: 500;
            font-size: 24px;
            color: #1a2744;
            display: flex;
            justify-content: center;
        }

        &__inputs {
            margin-top: 50px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 1270px) {
                flex-direction: column;
            }

            .error-label {
                font-size: 12px;
            }

            .user-project__left {
                padding-right: 40px;

                @media (max-width: 1270px) {
                    padding-right: 0;
                }
            }
            
            .user-project__left, .user-project__right {
                width: 50%;
                position: relative;

                @media (max-width: 1270px) {
                    width: 100%;
                }

                .input-label, .select-label {
                    font-size: 12px;
                    color: #1a2744;
                    position: relative;
                    left: 20px;
                    bottom: 5px;

                    &:before {
                        content: '*';
                        color: #ff0048;
                        position: relative;
                        right: 4px;
                    }
                }

                .select-label {
                    bottom: 0;
                }

                .user-project__project-field {
                    margin-top: 20px;
                    position: relative;
                }

                .user-project__project-name, .user-project__place, .user-project__description {
                    .input-project-name {
                        display: block;
                        outline: none;
                        width: 100%;
                        height: 50px;
                        border: 1px solid #cdd4e0;
                        border-radius: 10px;
                        font-size: 16px;
                        font-weight: 500;
                        padding-left: 20px;

                        &:focus {
                            border-color: #ffc900;
                        }
                    }
                }

                .user-project__description, .user-project__file {
                    .input-label:before {
                        content: '';
                    }
                }

                .multiselect {
                    border-radius: 10px;
                    width: 100%;
                    min-height: 50px;
                    border: solid 1px #cdd4e0;
                    cursor: pointer;
                    position: relative;
                    align-items: center;
                    padding-left: 20px;

                    &:focus {
                        border-color: #ffc900;
                    }

                    .multiselect__content-wrapper {
                        position: absolute;
                        background-color: rgb(145, 34, 34);
                        font-size: 16px;
                        width: 100%;
                        left: 0px;
                        padding: 15px;
                    }
                }
            }

            .user-project__right {
                .user-project__description {
                    margin-top: 20px;

                    @media (max-width: 1270px) {
                        margin-top: 20px;
                    }

                    .input-project-name {
                        resize: none;
                        padding: 20px;
                        width: 100%;
                        height: 140px;
                    }
                }

                .user-project__inputs {
                    margin-top: 0;
                }
            }

            .user-project__file {
                margin-top: 20px;

                .label-for-file {
                    border-radius: 10px;
                    width: 100%;
                    height: 50px;
                    border: solid 1px #cdd4e0;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;

                    span {
                        max-width: 85%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        display: block;
                    }

                    &:after {
                        content: url('../assets/images/attach-paperclip-symbol.png');
                        cursor: pointer;
                        position: absolute;
                        right: 20px;
                        top: 15px;
                    }
                }
            }
        }

        &__btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 40px;

            @media (max-width: 1270px) {
                margin-bottom: 40px;
            }

            .btn-cancel, .btn-save {
                font-family: @secondFontFamily;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.33;
                width: 220px;
                height: 50px;
                background-color: #1a2744;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                margin-right: 11px;
                cursor: pointer;
                box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);

                @media (max-width: 480px) {
                    width: 50%;
                }
            }

            .disableBtn {
                opacity: 0.4;
                cursor: auto;
                box-shadow: none;
            }
        }

    }

    .form-popup {
        position: fixed;
        background-color: #1a2744;
        width: 200vw;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.6;
        z-index: 1;
    }

    .popup-container {
        z-index: 5;
        position: absolute;
        left: 50%;
        top: 45%;
        width: 610px;
        max-width: 100%;
        border-radius: 10px;
        box-shadow: 0 24px 24px -14px rgba(157, 163, 174, 0.1);
        border: solid 1px #cdd4e0;
        background-color: #f5f5f5;
        padding: 31px 74px 48px 80px;
        transform: translate(-50%, 0);

        .close-popup {
            position: absolute;
            cursor: pointer;
            right: 14px;
            top: 14px;
        }

        .popup-title {
            font-family: @secondFontFamily;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.17px;
            color: #1a2744;
            position: relative;

            &:before {
                content: url("../assets/images/info.svg");
                position: absolute;
                left: -40px;
                top: 2px;
            }
        }

        .popup-description {
            margin-top: 9px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.11px;
            color: #1a2744;
        }
    }
}

//.multiselect__input {
//    height: 50px;
//    border: 0;
//    font-size: 14px;
//    position: absolute;
//    padding: 0px;
//}

.user-project__user-init{
    @media (max-width: 992px){
        margin-bottom: 40px;
    }
    .multiselect{
        border: none!important;
        padding: 5px 0!important;

    }
    ::v-deep{

        .multiselect__placeholder{
            display: none;
        }
        .multiselect__input{
            border-radius: 10px;
            width: 100%!important;
            min-height: 50px;
            border: 1px solid #cdd4e0;
            cursor: pointer;
            position: relative;
            align-items: center;
            padding-left: 20px;
            &:focus-visible{
                outline: none;
            }
            &::placeholder{
                padding-left: 20px;
            }
        }



        .multiselect__content{
            display: inline-block;
            width: 100%;
            border: 1px solid #cdd4e0;
            position: absolute;
            max-height: 200px;
            overflow-y: scroll;
            border-top: none;
            z-index: 999;
            background-color: white;
            //@media (max-width: 992px){
            //    position: static;
            //}
        }
        .multiselect__element{
            &::marker{
                content: ''!important;
                display: none!important;
            }
        }

        .multiselect__option{
            span{
                line-height: 1.5;
                font-family: @defaultFontFamily;
                font-size: 1em;
                width: 100%;
            }
        }
        .multiselect__option--highlight{
            background-color: #ffc900!important;
            color: #fff!important;
        }

        .multiselect--active{
            .multiselect__input{
                border-bottom: none;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                border-color: #ffc900;
                z-index: 9;
            }
            .multiselect__content{
                border-color: #ffc900;
            }
        }
    }
}
.activeContent{
    ::v-deep{
        .multiselect--active{
            .multiselect__input{
                border-left: 0;
                border-right: 0;
            }
        }
        .multiselect__tags{
            border: 1px solid #cdd4e0;
            border-bottom: none;
            border-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .multiselect__input{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            left: 0;
        }
        .custom__tag{
            display: flex;
            padding-top: 13px;
            padding-left: 20px;
        }
        .custom__remove{
            width: 10px;
            height: 10px;
            display: block;
            position: relative;
            &:before{
                content: '';
                background-image: url(../assets/images/cancel_black_24dp.svg);
                background-size: cover;
                position: absolute;
                top: 3px;
                left: 6px;
                height: 15px;
                width: 15px;
                cursor: pointer;
            }
        }
    }
}

</style>
